import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Deployments</AnchorLink>
  <AnchorLinks small mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Servers</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Stations</AnchorLink>
  </AnchorLinks>
    </AnchorLinks>
    <InlineNotification mdxType="InlineNotification">
  To see images/gifs/vidoes below, please connect to VPN
    </InlineNotification>
    <h2>{`Features`}</h2>
    <h3>{`Deployments`}</h3>
    <ul>
      <li parentName="ul">{`New Download Log button for server workflow step and station upgrade step outputs that are longer than ~25k characters, that allows the user to download the step output as a text file.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.4.3%252FDownloadLogButton.png&isNativeBrowsing=false",
          "alt": "New action button"
        }}></img></li>
    </ul>
    <h4>{`Servers`}</h4>
    <ul>
      <li parentName="ul">{`Server component’s More Options (3 vertical dots) button has been replaced with Execute Action button (lightning bolt), that now brings up Execute Action dialog right away saving a user an extra click.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.4.3%252FNewActionButton.png&isNativeBrowsing=false",
          "alt": "New action button"
        }}></img></li>
    </ul>
    <ul>
      <li parentName="ul">{`If an action is executed on a particular component, the user will not be able to click Start Step Execution button in the worklow step if the step is supposed to run on the same component as the one, which the action is currently running on. In turn, new Execute Action will be disabled on the component if a workflow step is currently running on corresponding component.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.4.3%252FExecuteAction.gif&isNativeBrowsing=false",
          "alt": "Execute action demo"
        }}></img>
        <img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.4.3%252FExecuteWorkflowStep.gif&isNativeBrowsing=false",
          "alt": "Execute workflow step demo"
        }}></img></li>
    </ul>
    <h4>{`Stations`}</h4>
    <ul>
      <li parentName="ul">{`Mostly empty view with Download Station Agent button and the link to Station Upgrades and Station Upgrades view have been merged into a single view giving it a user experience consistent with Server Deployments view
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.4.3%252FUpdatedStationDeploymentUI.gif&isNativeBrowsing=false",
          "alt": "Execute workflow step demo"
        }}></img></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      